import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';
import IconPlanetRed from '~/assets/images/content/main/ic_sphere_red.png';
import IconPlanetBlue from '~/assets/images/content/main/ic_sphere_blue.png';
import IconPlanetGreen from '~/assets/images/content/main/ic_sphere_green.png';
import IconPlanetYellow from '~/assets/images/content/main/ic_sphere_yellow.png';

export const TabList = [
  {
    name: 'All'
  },
  {
    name: 'PERSOPN',
    icon: IconPerson
  },
  {
    name: 'PLANET',
    icon: IconEarth
  }
];

export const MainContentList = [
  {
    title: 'HEALTH',
    icon: IconPlanetGreen,
    contentList: [
      {
        title: 'Vitality and Longevity',
        titleColor: '#56B701',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Personal Guides',
            subList: [
              'Health Psychology',
              'Life Force Food',
              'Longevity Activities',
              'Physiological Efficiencies',
              'Natural Remedies',
              'Purity'
            ]
          }
        ]
      },
      {
        title: ' Bio-Interdependence',
        titleColor: '#56B701',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Global Guides',
            subList: [
              'Homeostasis',
              'Bio-Interdependence',
              'Life-logical systems',
              'Diversity Protection',
              'Green Ingredients',
              'Eco Processes',
              'Positive Functionality',
              'Trade Integrity'
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'PROSPERITY',
    icon: IconPlanetBlue,
    contentList: [
      {
        title: 'Wisdom and Successes',
        titleColor: '#4F91E9',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Personal Guides',
            subList: [
              'Contextual Intelligence',
              'Experiential Learning',
              "Key Literacy's",
              'Unique Passion Flow',
              'Mastermind S.P.',
              'Ethical Entrepreneur'
            ]
          }
        ]
      },
      {
        title: 'Inter-generational equity',
        titleColor: '#4F91E9',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Global Guides',
            subList: [
              'Increase Economics',
              'Economic Justice',
              'G.P.M.',
              'True Democracy',
              'Constitution',
              'Treaty',
              'G.E.I.'
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'INSPIRATION',
    icon: IconPlanetRed,
    contentList: [
      {
        title: 'Empowerment and Significance',
        titleColor: '#FD22FE',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Personal Guides',
            subList: [
              'Conscious Com.',
              'Soul Mate Nature',
              'New Being Nuture',
              'Advanced Parenting',
              'Abundant Living',
              'Significant Media'
            ]
          }
        ]
      },
      {
        title: 'Unification and Belonging',
        titleColor: '#FD22FE',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Global Guides',
            subList: [
              'Accurate History',
              'Net Neutrality',
              'U & I Media',
              'S & I Media',
              'Contextual intelligence ',
              'Wisdom teachers',
              'Education Integral'
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'JOY',
    icon: IconPlanetYellow,
    contentList: [
      {
        title: 'Empowerment and Significance',
        titleColor: '#D2A75F',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Personal Guides',
            subList: ['Capacity & Service', 'Magnanimity & Grace', 'Vib. Transcendence ', 'Source', 'Authenticity & P.O.Ps', 'Godforce']
          }
        ]
      },
      {
        title: 'Unification and Belonging',
        titleColor: '#D2A75F',
        itemList: [
          {
            text: 'Quotes'
          },
          {
            text: 'Articles'
          },
          {
            text: 'Videos'
          },
          {
            text: 'Metawheel Global Guides',
            subList: [
              'Legitimate Law',
              'Restorative Justice',
              'Societal Increase',
              'Spiritual Authenticity',
              'Spiritual Singualarity'
            ]
          }
        ]
      }
    ]
  }
];
