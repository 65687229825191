import React, {useState} from 'react';
import {withStyles, Collapse} from '@material-ui/core';

import IconIndicatorOpen from '~/assets/images/content/main/ic_indicator_open.png';
import IconIndicatorClose from '~/assets/images/content/main/ic_indicator_close.png';

const styles = (theme) => ({
  root: {
    marginTop: '10px',
    fontSize: '13px',
    color: '#686868'
  },
  itemOpenIndicator: {
    position: 'absolute',
    left: '-20px',
    top: '4px',
    width: '10px'
  },
  itemTitleWrapper: {
    position: 'relative',
    marginTop: '10px'
  },
  subListWrapper: {
    marginLeft: '15px',
    marginTop: '10px'
  },
  subListItem: {
    marginTop: '3px'
  },
  oneItem: {
    cursor: 'pointer'
  }
});

function ContentItemList(props) {
  const [open, setOpen] = useState(false);

  const {classes, contentItem} = props;

  return (
    <div className={classes.root}>
      <div
        className={classes.title}
        style={{
          color: contentItem.titleColor
        }}
      >
        {contentItem.title}
      </div>
      {contentItem.itemList.map((item) => (
        <div>
          <div
            className={classes.itemTitleWrapper}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {item.subList ? (
              <img
                src={open ? IconIndicatorOpen : IconIndicatorClose}
                className={classes.itemOpenIndicator}
                alt="indicator"
              />
            ) : null}
            <span className={classes.oneItem}>{item.text}</span>
          </div>
          {item.subList ? (
            <Collapse in={open}>
              <div className={classes.subListWrapper}>
                {item.subList.map((subItem) => (
                  <div className={classes.subListItem}>{subItem}</div>
                ))}
              </div>
            </Collapse>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default withStyles(styles)(ContentItemList);
