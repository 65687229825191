// @ts-check
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Image1 from '~/assets/images/content/articles.png';
import Image2 from '~/assets/images/content/videos.png';
import Image3 from '~/assets/images/content/quotes.png';
import Image4 from '~/assets/images/content/initiative_planet_bg.png';
import Image5 from '~/assets/images/content/kimLarkingReel.png';
import Image6 from '~/assets/images/content/kimLarkingreel2.png';

import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';
import IconItemBlue from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-blue.png';
import IconItemGreen from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-green.png';
import IconItemPink from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-pink.png';
import IconItemYellow from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-yellow.png';
import { ArrowBack, Comment, Share, ThumbUpSharp } from '@material-ui/icons';
import profilePic from '~/assets/images/profile/temp-photos/IMG_4964.jpg';
import { useQuery } from 'react-query';
import { fetchHCNArticleCategoriesWarper, fetchUserArticlesByCategoryIds } from 'shared/react-query/strapiAPI';
import MyFeed from "~/components/layout/MyFeed/index";
import { useState } from 'react';
import ArticleOverlayImage from './ArticleOverlayImage';
import DisplayContentItems from '~/pages/content/contentData/DisplayContentItems';
import { useAuth } from 'shared/auth';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ArticleDetailItem } from './ArticleDetailItem';

const customStylesProfileFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'rgb(205 205 205)',
  },
  subCategoryButton: {
    borderBottom: '2px solid transparent',
  },
});

const ProfileFeedCustomStyles = withStyles(customStylesProfileFeed)(MyFeed);

const PERSON = "person";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
    // backgroundColor: theme.palette.background.paper
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gridGap: '4px',
    width: '100%',
    margin: 0,
    boxSizing: 'border-box'
  },
  gridContainer2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gridGap: '4px',
    width: '100%',
    margin: 0,
    boxSizing: 'border-box'
  },
  gridItem: {
    textAlign: 'center',
    aspectRatio: '1/1',
    color: theme.palette.text.secondary
  },
  selected: {
    color: 'red !important'
  },
  switch: {
    width: '200px'
  },
  switcherIcon: {
    display: 'inline-block',
    marginRight: '8px',
    width: '15px'
  },

  switchLeft: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px'
  },
  switchRight: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px'
  },
  switchActive: {
    background: '#023B78',
    color: 'white'
  },
  tabWrapper: {
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    '& img': {
      marginBottom: '0px !important'
    }
  },
  tabRoot: {
    minWidth: 0,
    '& > *': {
      minWidth: 0
    }
  },
}));

const ProfileFeed = () => {
  const classes = useStyles();
  const { profileData } = useAuth();
  const { id: slug } = useParams();
  const profileSlug = slug ?? profileData?.slug;
  const mode = PERSON;

  const allCategories = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategoriesWarper(),
    { staleTime: Infinity }
  );

  const [categories, setCategories] = useState(null);

  const onCategoriesChangeHandler = (selectedCategories) => {
    setCategories(selectedCategories);
  };

  const [displayExpandedArticleSlug, setDisplayExpandedArticleSlug] = React.useState(null);

  let articlesByCategories = useQuery([`user-${profileSlug}-articles`, categories?.map(cat => cat.id).join("-")], () =>
    fetchUserArticlesByCategoryIds(profileSlug, categories?.map(cat => cat.id), 1),
    {
      enabled: displayExpandedArticleSlug !== null && !!categories && categories.length > 0,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );

  // console.log("articlesByCategories", displayExpandedArticleSlug !== null && !!categories && categories.length > 0, articlesByCategories);

  // useEffect(() => {
  //   if (displayExpandedArticleSlug == null) {
  //     return;
  //   }
  //   // setTimeout(() => {
  //   //   document
  //   //     .getElementById(`${displayExpandedArticle[0]}-${displayExpandedArticle[1]}`)
  //   //     .scrollIntoView({behavior: 'smooth'});
  //   // }, 1000);
  // }, [displayExpandedArticleSlug]);

  const goDetail = (slug) => {
    setDisplayExpandedArticleSlug(slug)
  };

  return (
    <div className={classes.root}>
      <ProfileFeedCustomStyles
        allCategories={allCategories.data ?? []}
        mode={mode} onCategoriesChange={onCategoriesChangeHandler} />

      {displayExpandedArticleSlug !== null ? (
        <div>
          <Button variant="text" startIcon={<ArrowBack />} onClick={() => setDisplayExpandedArticleSlug(null)}>
            Go Back
          </Button>
          <div className={classes.gridContainer} style={{ gridTemplateColumns: 'repeat(1,1fr)' }}>

            <div style={{ flexDirection: 'column', display: 'flex', gap: 4 }}>
              {articlesByCategories?.data?.map((item, index2) => (
                <ArticleDetailItem key={index2}
                  category={allCategories.data.find(cat => cat.id === item.category.id)}
                  item={item} index={0} index2={index2} profilePic={profilePic} />
              )
              )}
            </div>
          </div>
        </div>
      ) : (
        <DisplayContentItems
          allCategories={allCategories.data ?? []}
          filteredCategories={categories}
          goDetail={goDetail}
          // how to fetch data
          dataName={`user-${profileSlug}-articles`}
          fetcher={(...args) => fetchUserArticlesByCategoryIds(profileSlug, ...args)}
          // how to display data
          itemComponent={ArticleOverlayImage}
        />
      )}
    </div>
  );
};

{
  /* <FeedTemplate
title="MY FEED"
titleIcon={IconTitle}
articleContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec."
articleDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi."
articleImg={ArticleBg}
isArticleVideo
/> */
}
export default ProfileFeed;
