import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import QuoteDetail from './QuoteDetailPage';
import QuotesMain from './Sections/QuotesMain';
import { styles } from './QuotesMainPageStyles';
import { useQuery } from 'react-query';
import { fetchHCNArticleCategoriesWarper } from 'shared/react-query/strapiAPI';

const PERSON = 'person';
const PLANET = 'planet';

const Quotes = ({
  classes,
  quotes,
  pagination,
  handleGetMoreQuotesByPage,
  history
}) => {
  const [detailPage, setDetailPage] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryCode] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [mode, setMode] = useState(PERSON);
  const [noMoreQuotes, setNoMoreQuotes] = useState(false);

  const allCategories = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategoriesWarper(),
    { staleTime: Infinity }
  );

  const loadMoreQuotes = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
      if (page < pagination.lastPage) {
        let nextPage = page + 1;
        setPage(nextPage);
        handleGetMoreQuotesByPage(nextPage);
      } else {
        setNoMoreQuotes(true);
      }
    }
  };

  useEffect(() => {
    // handleGetPagination();
    // window.addEventListener('scroll', loadMoreQuotes);
    // return () => window.removeEventListener('scroll', loadMoreQuotes);
  }, []);

  const goDetail = (id, category) => {
    setDetailPage(true);
    setQuoteId(id);
    setCategoryCode(category);
    // history.push(`/detail?id=${id}`);
  };

  const handleTitlesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const onCategoriesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const handleDataFetch = (dataFetched) => {
    setFilteredData(dataFetched);
  };

  const handleCategoryChange = (categoryId) => {
    setDetailPage(false);
  };

  const data =
    selectedCategories.length > 0
      ? quotes.filter((item) => selectedCategories.indexOf(item.category) > -1)
      : quotes;

  return (
    <div className={classes.backgroundWrapper}>
      <div className={classes.content}>
        {detailPage ? (
          <QuoteDetail
            allCategories={allCategories.data ?? []}
            filteredCategories={selectedCategories} quotes={filteredData}
            categoryId={categoryId} quoteId={quoteId}
            onCategoryChange={handleCategoryChange} />
        ) : (
            <QuotesMain
              allCategories={allCategories.data ?? []}
              data={data}
              selectedCategories={selectedCategories}
              onCategoriesChange={onCategoriesChange}
              onDataFetch={handleDataFetch}
              onTitlesChange={handleTitlesChange}
              goDetail={goDetail}
              mode={mode}
              noMoreQuotes={noMoreQuotes}
              handleSwitch={() => {
              const nextMode = mode === PERSON ? PLANET : PERSON;
              setMode(nextMode);
              history.replace(`?${nextMode}=true`);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Quotes);
