import {useQuery, useMutation, useQueryClient, useInfiniteQuery} from 'react-query';
import client from './shopifyAPI';
import {
  FETCH_PRODUCTS,
  ADD_PRODUCT_TO_CART,
  CREATE_CART,
  FETCH_CART,
  CART_ITEM_QUANTITY_UPDATE,
  CART_ITEM_REMOVE,
  FETCH_PRODUCT,
  FETCH_LIFEINGREDIENTS,
  FETCH_RELATEDPRODUCTS,
  FETCH_RELATEDPRODUCTSFORPAGE,
  LOGIN_CUSTOMER,
  TOKEN_RENEW_CUSTOMER,
  CREATE_CUSTOMER,
  ASSOCIATE_CHECKOUT,
  FETCH_ORDER_HISTORY,
  FETCH_CUSTOMER,
  FETCH_ORDER_HISTORY_WITH_LINEITEMS,
} from './shopifyQueries';

export const useFetchProducts = (variables) => {
  return useInfiniteQuery(
    ['products', variables],
    ({pageParam}) => {
      return client.request(FETCH_PRODUCTS, {...variables, cursor: pageParam});
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.collectionByHandle?.products?.pageInfo?.hasNextPage) {
          return undefined;
        }
        return lastPage.collectionByHandle?.products?.pageInfo?.endCursor ?? undefined;
      },
    }
  );
};

export const useFetchLifeIngredients = () => {
  return useQuery({
    queryKey: ['lifeIngredients'],
    queryFn: () => client.request(FETCH_LIFEINGREDIENTS),
  });
};


export const useFetchCart = (variables) => {
  return useQuery({
    queryKey: ['cart', variables],
    queryFn: () => client.request(FETCH_CART, variables),
    enabled: !!variables?.id,
  });
};

export const useFetchRelatedProducts = (variables) => {
  return useQuery({
    queryKey: ['products', variables],
    queryFn: () => client.request(FETCH_RELATEDPRODUCTS, variables),
    enabled: !!variables?.id,
  });
};

export const useAddProductToCart = () => {
  const queryClient = useQueryClient();

  return useMutation(({cartId, lineItems}) => client.request(ADD_PRODUCT_TO_CART, {cartId, lineItems}), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('cart');
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useUpdateCartItemQuantity = () => {
  const queryClient = useQueryClient();

  return useMutation(({cartId, lineItems}) => client.request(CART_ITEM_QUANTITY_UPDATE, {cartId, lineItems}), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('cart');
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useRemoveCartItem = () => {
  const queryClient = useQueryClient();

  return useMutation(({cartId, lineIds}) => client.request(CART_ITEM_REMOVE, {cartId, lineIds}), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('cart');
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useCreateCart = () => {
  return useMutation(() => client.request(CREATE_CART));
};

export const useFetchProduct = (variables) => {
  return useQuery(['product', variables], () => client.request(FETCH_PRODUCT, variables));
};

export const useFetchRelatedProductsForPage = () => {
  return useQuery(['product'], () => client.request(FETCH_RELATEDPRODUCTSFORPAGE));
};


export const useLoginCustomer = () => {

  return useMutation(({input}) => client.request(LOGIN_CUSTOMER, {input}), {
    onSuccess: (data) => {
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useTokenRenewCustomer = () => {
  return useMutation(({customerAccessToken}) => client.request(TOKEN_RENEW_CUSTOMER, {customerAccessToken}), {
    onSuccess: (data) => {
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useCreateCustomer = () => {
  return useMutation(({input}) => client.request(CREATE_CUSTOMER, {input}), {
    onSuccess: (data) => {
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useAssociateCheckout = () => {
  return useMutation(({buyerIdentity,cartId}) => client.request(ASSOCIATE_CHECKOUT, {buyerIdentity,cartId}), {
    onSuccess: (data) => {
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useFetchOrderHistory = (variables) => {
  return useQuery({
    queryKey: ['orderHistory', variables],
    queryFn: () => client.request(FETCH_ORDER_HISTORY, variables),
    enabled: !!variables?.token
  });
};

export const useFetchCustomer = (variables) => {
  return useQuery({
    queryKey: ['customer', variables],
    queryFn: () => client.request(FETCH_CUSTOMER, variables),
    enabled: !!variables?.token
  });
};


export const useFetchOrderHistoryWithLineitems = (variables) => {
  return useQuery({
    queryKey: ['orderHistory', variables],
    queryFn: () => client.request(FETCH_ORDER_HISTORY_WITH_LINEITEMS, variables),
  });
};

