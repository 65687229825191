import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {withStyles, Grid} from '@material-ui/core';

import OrganizeLandingStyles from './OrganizeLandingStyles';
import icon_m from '~/assets/images/organize/icon_m.png';

import data from '~/data/organizeLandingData';
import MetalWheelPersonalChart from './Sections/MetalWheelPersonalChart';

class OrganizeLanding extends Component {
  state = {
    current: 0,
    image: data.image,
    switcherText: data.switcherText,
    descriptionTop: data.descriptionTop,
    descriptionBottom: data.descriptionBottom
  };

  switchMetaWheel = (index) => {
    this.setState({
      current: index
    });
  };

  render() {
    const {classes} = this.props;
    const {current, image, switcherText, descriptionTop, descriptionBottom} = this.state;

    let listItems = switcherText.map((ele, index) => {
      let activeText = index === current ? classes.metawheel_switcher_text_active : '';
      let activeBtn = index === current ? classes.metawheel_switcher_btn_active : '';

      return (
        <li className={classes.metawheel_switcher_item} key={index}>
          <div
            className={`${classes.metawheel_switcher_text} ${activeText}`}
            onClick={() => this.switchMetaWheel(index)}
          >
            {switcherText[index]}
          </div>

          <button
            className={`${classes.metawheel_switcher_btn} ${activeBtn}`}
            onClick={() => this.switchMetaWheel(index)}
          ></button>
        </li>
      );
    });

    return (
      <>
        <div className={classes.black_border}></div>

        <Grid container className={classes.metawheel_content} direction="column" align="center">
          <Grid item>
            <Typography className={classes.metawheel_description}>{descriptionTop[current]}</Typography>
          </Grid>

          <Grid item container className={classes.metawheel_switcher}>
            <Grid item md={2} />

            <Grid item xs={12} md={8}>
              <ul className={classes.metawheel_switcher_list}>{listItems}</ul>
              <hr className={classes.metawheel_switcher_hr} />
            </Grid>

            <Grid item container className={classes.getApp} xs={12} md={2}>
              <RouterLink to="#" className={classes.getAppLink}>
                <Grid item container>
                  <Grid item>
                    <img className={classes.icon_m} src={icon_m} alt="icon_m" />
                  </Grid>

                  <Grid item>
                    <Typography className={classes.getAppText}>{data.getAppText}</Typography>
                  </Grid>
                </Grid>
              </RouterLink>
            </Grid>
          </Grid>

          <Grid item>
            {current === 1 ? (
              <MetalWheelPersonalChart />
            ) : (
              <img src={image[current]} className={classes.metawheelImage} alt={switcherText[current]} />
            )}
          </Grid>

          <Grid item>
            <Typography className={classes.metawheel_description}>{descriptionBottom[current]}</Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

OrganizeLanding.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withRouter(withStyles(OrganizeLandingStyles)(OrganizeLanding));
