import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { ExpandBar, ToggleContentTitles } from "~/components/ui";
import styles from "./QuotesMainStyles";
import MyFeed from "~/components/layout/MyFeed/index";
import { fetchQuotesByCategoryIds } from "shared/react-query/strapiAPI";
import ShadableCard from "./ShadableCard";
import DisplayContentItems from "../../contentData/DisplayContentItems";
import { useState } from "react";

const customStylesMyFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'transparent',
  },
  subCategoryButton: {
  },
});

const MyFeedCustomStyles = withStyles(customStylesMyFeed)(MyFeed);

const useStyles = makeStyles(styles);

const QuotesMain = (props) => {
  const classes = useStyles(props);
  const {
    allCategories,
    data,
    selectedCategories,
    onTitlesChange,
    mode,
    noMoreQuotes,
    handleSwitch,
    onDataFetch,
    onCategoriesChange,
  } = props;

  const [categories, setCategories] = useState(null);

  const onCategoriesChangeHandler = (selectedCategories) => {
    setCategories(selectedCategories);
    onCategoriesChange(selectedCategories);
  };

  return (
    <Container className={classes.root}>
      <ExpandBar
        nav="Quotes"
        subNav={mode}
        goExpanded={props.goExpanded}
        switcher={{
          items: ["Person", "Planet"],
          checkedIndex: mode === "planet" ? 1 : 0,
          onChange: handleSwitch,
        }}
      />
      <ToggleContentTitles
        categories={categories}
        selectedCategories={selectedCategories}
        onChange={onTitlesChange}
      />
      <MyFeedCustomStyles allCategories={allCategories} data={data} mode={mode} onCategoriesChange={onCategoriesChangeHandler} />

      {noMoreQuotes ? (
        <Grid container justifyContent="center">
          <Typography component="p" className={classes.noMoreQuotes}>
            No More Quotes
          </Typography>
        </Grid>
      ) : (
        <DisplayContentItems
          allCategories={allCategories}
          filteredCategories={categories}
          goDetail={props.goDetail}
          // how to fetch data
          dataName="videos"
          fetcher={fetchQuotesByCategoryIds}
            onDataFetch={onDataFetch}
          // how to display data
          itemComponent={ShadableCard}
        />)}
    </Container>
  );
};

export default QuotesMain;
