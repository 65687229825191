import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import MarkdownView from 'react-showdown';
import ArticleToolbar from './ArticleToolbar';
import ArticleShortcuts from './ArticleShortcuts';
import styles from './ArticleContentStyles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(styles);

const ArticleContent = ({article}) => {
  const classes = useStyles();
  
  const {
    title,
    body,
    publishDate,
    coverImage,
    tags,
    likesCount,
    favoritesCount,
    commentsCount
  } = article;
  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Box>
        <ArticleShortcuts publishDate={publishDate} />
      </Box>
      <Box py={1} style={{position: 'relative'}}>
        <img src={coverImage} className={classes.coverImage} alt={title} />
        <div style={{position: 'absolute', top: 13, width: '100%', height: '100%'}}>
        </div>
      </Box>
      <ArticleToolbar
        tags={tags}
        likesCount={likesCount}
        favoritesCount={favoritesCount}
        commentsCount={commentsCount}
      />
      <Box>
      <MarkdownView
      markdown={body}
      
    />
      </Box>
    </div>
  );
};

export default ArticleContent;
