import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import MetaWheelImage from '~/assets/images/icons/metawheelAppBar.png';
import TREASUREBOXImage from '~/assets/images/content/articles/TREASURE-BOX.png';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import PublishDate from './PublishDate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 36,
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginBottom: 8,
    }
  },
  shortcutContainer: {
    display: 'flex',
    marginRight: 26,
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      marginBottom: 2
    }
  },
  shortcutIcon: {
    fontSize: '1.5rem',
    color: '#b1b1b1'
  },
  shortcutText: {
    marginLeft: 8,
    color: '#919191',
    opacity: 0.82,
    fontSize: '0.875rem',
    fontWeight: 700,
    letterSpacing: 1.4
  },
  publishDateContainer: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: 16,
      marginLeft: 4
    }
  },
  metaWheelIcon: {
    width: '1.5rem',
    height: '1.5rem'
  },
  metaWheelText: {
    color: '#379800',
    letterSpacing: 2.1
  },
  addToText: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: 5
    }
  },
  TREASUREBOXIcon: {
    width: '2rem',
    height: '2rem',
    [theme.breakpoints.only('xs')]: {
      marginLeft: -5
    }
  }
}));

const ArticleShortcuts = ({publishDate}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.shortcutContainer, classes.publishDateContainer)}>
        <PublishDate date={publishDate} />
      </Box>
      <Box className={classes.shortcutContainer}>
        <img
          src={MetaWheelImage}
          alt={intl.get(messageKeys.ARTICLE_DETAIL_SHORTCUT_ACTION_VITALITY_LONGEVITY)}
          className={clsx(classes.actionIcon, classes.metaWheelIcon)}
        />
        <Typography className={clsx(classes.shortcutText, classes.metaWheelText)}>
          Purity
        </Typography>
      </Box>
      <Box className={classes.shortcutContainer}>
        <img
          src={TREASUREBOXImage}
          alt={intl.get(messageKeys.ARTICLE_DETAIL_SHORTCUT_ACTION_ADD_TO_TREASURE_BOX)}
          className={clsx(classes.actionIcon, classes.TREASUREBOXIcon)}
        />
        <Typography className={clsx(classes.shortcutText, classes.addToText)}>
          {intl.get(messageKeys.ARTICLE_DETAIL_SHORTCUT_ACTION_ADD_TO_TREASURE_BOX)}
        </Typography>
      </Box>
      <Box className={classes.shortcutContainer}>
        <PrintOutlinedIcon className={classes.shortcutIcon} />
        <Typography className={classes.shortcutText}>
          {intl.get(messageKeys.ARTICLE_DETAIL_SHORTCUT_ACTION_PRINT)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ArticleShortcuts;
