import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  ExpandBar,
  ToggleContentTitles,
} from "~/components/ui";
import styles from "./VideosMainStyles";

import MyFeed from "~/components/layout/MyFeed/index";
import VideoCardBox from "./VideoCardBox";
import DisplayContentItems from "../../contentData/DisplayContentItems";
import { fetchVideosByCategoryIds } from "shared/react-query/strapiAPI";

const useStyles = makeStyles(styles);

const customStylesMyFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'transparent',
  },
  subCategoryButton: {
  },
});

const MyFeedCustomStyles = withStyles(customStylesMyFeed)(MyFeed);

const VideosMain = (props) => {
  const classes = useStyles(props);
  const {
    allCategories,
    data,
    // categories,
    selectedCategories,
    onTitlesChange,
    mode,
    noMoreVideos,
    handleSwitch,
    onDataFetch,
  } = props;

  const [categories, setCategories] = useState(null);

  const onCategoriesChangeHandler = (selectedCategories) => {
    setCategories(selectedCategories);
    props.onCategoriesChange(selectedCategories);
  };

  const getCardData = () => {
    return data.concat([{ card: "MORE_VIDEOS", content: "LOAD MORE VIDEOS" }]);
  };

  return (
    <>
      <Container className={classes.root}>
        <ExpandBar
          nav="Videos"
          subNav={mode}
          goExpanded={props.goExpanded}
          switcher={{
            items: ["Person", "Planet"],
            checkedIndex: mode === "planet" ? 1 : 0,
            onChange: handleSwitch,
          }}
        />
        <ToggleContentTitles
          categories={categories}
          selectedCategories={selectedCategories}
          onChange={onTitlesChange}
        />
        <MyFeedCustomStyles allCategories={allCategories} data={data} mode={mode} onCategoriesChange={onCategoriesChangeHandler} />
        {noMoreVideos ? (
          <Grid container justifyContent="center">
            <Typography component="p" className={classes.noMoreVideos}>
              No More Videos
            </Typography>
          </Grid>
        ) :
          (
            <DisplayContentItems
              allCategories={allCategories}
              filteredCategories={categories}
              goDetail={props.goDetail}
              // how to fetch data
              dataName="videos"
              fetcher={fetchVideosByCategoryIds}
              onDataFetch={onDataFetch}
              // how to display data
              itemComponent={VideoCardBox}
            />)}
      </Container>
    </>
  );
};

export default VideosMain;
