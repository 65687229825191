import React, { useState } from 'react'
import { withStyles, Grid } from '@material-ui/core';

import Tab from './Sections/Tab'
import ContentItemList from './Sections/ContentItemList'

import { TabList, MainContentList } from '~/data/contentMainData'

const styles = theme => ({
  root: {
    padding: '40px 60px'
  },
  title: {
    color: '#D2A75F',
    fontSize: '20px',
    letterSpacing: '2px',
    margin: '20px 0 40px 0'
  },
  itemIcon: {
    width: '30px',
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginLeft: '10px'
  },
  itemListWrapper: {
    marginLeft: '40px'
  },
  mainConentItem: {
    marginBottom: '20px'
  }
})

function Content(props) {
  const [selectIndex, setSelectIndex] = useState(0)
  const { classes } = props

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Tab
          tabs={TabList}
          selectIndex={selectIndex}
          onTabSelect={(tabIndex) => {
            setSelectIndex(tabIndex)
          }} />
      </Grid>
      <div className={classes.title}>
        Content
      </div>
      <Grid container>
        {
          MainContentList.map(item => (
            <Grid item md={3} sm={6} xs={12} className={classes.mainConentItem}>
              <Grid container alignItems="center">
                <img src={item.icon} className={classes.itemIcon} alt="icon" />
                <span className={classes.itemTitle}>{item.title}</span>
              </Grid>
              <div className={classes.itemListWrapper}>
                {
                  selectIndex === 0 || selectIndex === 1 ?
                    <ContentItemList contentItem={item.contentList[0]} /> : null
                }
                {
                  selectIndex === 0 || selectIndex === 2 ?
                    <ContentItemList contentItem={item.contentList[1]} /> : null
                }
              </div>

            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Content)