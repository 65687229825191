import React, {useState, useEffect}  from 'react';
import {withStyles, Typography, Modal, Grid} from '@material-ui/core';
import {ProfileCard} from '~/components/ui';
import clsx from 'clsx';

import metawheelImg from '~/assets/images/profile/metawheel.png';
import metawheelLarge from '~/assets/images/profile/metawheel-large.png';
import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';


const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex:1,
    minWidth:300,
  },

  title: {
    fontSize: '1rem',
    textAlign: 'center',
    color: '#262626',
    margin: '10px auto 5px'
  },
  metawheel: {
    objectFit: 'contain',
    display: 'block',
    width: '90%',
    margin: 'auto',
    cursor: 'pointer'
  },
  metaModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  metaBox: {
    width: '720px',
    height: '720px',
    backgroundColor: 'rgba(255,255,255,0.9)',
    boxShadow: 'none',
    borderRadius: '30px',
    padding: '20px'
  },
  metaLarge: {
    display: 'block',
    width: '100%',
    height: '100%',
    margin: 'auto'
  },
  switcherIcon: {
    display: 'inline-block',
    marginRight: '8px',
    width: '15px'
  },

  switchLeft: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px'
  },
  switchRight: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px'
  },
  switchActive: {
    background: '#023B78',
    color: 'white'
  }
});

function MetawheelCard(props) {
  const {classes} = props;
  const [openModal, setModalOpen] = useState(false);
  const [switcher, setSwitcher] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850); // Adjust the width threshold as needed
    };
    handleResize(); // Check initial width
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up
    };
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const body = (
    <div className={classes.metaBox}>
      <img src={metawheelLarge} alt="meta wheel" className={classes.metaLarge} />
    </div>
  );

  return (
    <ProfileCard className={classes.root} isMatchParent={false}>
      <Typography onClick={isMobile ? toggleCollapse : undefined} className={classes.title} component="div" style={{cursor:isMobile?"pointer":"initial",
     margin: isCollapsed ? '0px auto' : '10px auto 5px'
    }} >
        METAWHEEL
      </Typography>
      {(!isMobile || !isCollapsed) && (
      <div style={{marginTop:"15px"}}>
      <img className={classes.metawheel} src={metawheelImg} alt="meta wheel" onClick={handleModalOpen} />
      <Modal open={openModal} onClose={handleModalClose} className={classes.metaModal}>
        {body}
      </Modal>

      <Grid container alignItems="center" className={classes.switch}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              className={clsx(classes.switchLeft, {
                [classes.switchActive]: switcher === 1
              })}
              onClick={() => setSwitcher(1)}
            >
              <img src={IconPerson} className={classes.switcherIcon} alt="icon" />
              <Typography>Person</Typography>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              className={clsx(classes.switchRight, {
                [classes.switchActive]: switcher === 2
              })}
              onClick={() => setSwitcher(2)}
            >
              <img src={IconEarth} className={classes.switcherIcon} alt="icon" />
              <Typography>Planet</Typography>
            </Grid>
          </Grid>
      </div>
      )}

    </ProfileCard>
  );
}

export default withStyles(styles)(MetawheelCard);
