import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import Sidebar from './Sections/Sidebar';
import SecondHeader from './Sections/SecondHeader';
import PersonCard from './Sections/PersonCard';
import MetawheelCard from './Sections/MetawheelCard';
import InsightsCard from './Sections/InsightsCard';
import SocialMediaCard from './Sections/SocialMediaCard';
import LeftList from './Sections/bottom/LeftList';
import RightContent from './Sections/bottom/RightContent';
import { profileListData, personalProfileListData } from '~/data/profileData';

import styles from './ProfileStyle';
import { fetchVisionaireProfile } from 'shared/react-query/strapiAPI';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { useAuth } from 'shared/auth';
import { isNil } from 'lodash';

function Profile(props) {
  const [selectIndex, setSelectIndex] = useState(0);
  const [selectTabIndex, setSelectTabIndex] = useState(2);
  const [isPersonal, setIsPersonal] = useState(true);
  const dataList = isPersonal ? personalProfileListData : profileListData;

  const { id: slug } = useParams();
  const { auth, profileData } = useAuth();
  const { data, error, isLoading } = useQuery(['visionaireProfile', slug, auth.token],
    () => fetchVisionaireProfile(slug ?? profileData?.slug, auth.token),
    {
      enabled: !isNil(slug ?? profileData?.slug),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 60 * 1000 * 100,
    });
  const isLoggedinUserProfile = !isNil(data?.data?.attributes?.email)
  console.log(isLoggedinUserProfile, data?.data?.attributes?.email, 'data?.email')

  const { classes, profile } = props;

  const handleSwitch = () => {
    setIsPersonal(!isPersonal);
    setSelectIndex(0);
    setSelectTabIndex(0);
  };

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    console.log(error.message)
    return <p>{error.message}</p>
  }

  if (!data) {
    return <></>
  }
  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <SecondHeader switchData={handleSwitch} />
        <div className={classes.userSection}>
          <Grid container spacing={3} className={classes.topSection}>
            <Grid item md={6} sm={12} xs={12}>
              <PersonCard data={data.data.attributes} />
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <MetawheelCard />
            </Grid>
            <Grid item md={4} sm={8} xs={12}>
              <InsightsCard />
              <br />
              <SocialMediaCard />
            </Grid>
          </Grid>
        </div>
        <div className={classes.bottomWrapper}>
          <Grid container className={classes.bottomSection}>
            {/* <Grid item className={classes.blSection}>
              <LeftList
                selectIndex={selectIndex}
                dataList={dataList}
                isPersonal={isPersonal}
                selectTabIndex={selectTabIndex}
                onItemClick={(index) => {
                  if (index !== selectIndex) {
                    setSelectIndex(index);
                    setSelectTabIndex(0);
                  }
                }}
              />
            </Grid> */}
            <Grid item className={classes.brSection}>
              <RightContent
                isPersonal={isPersonal}
                selectTabIndex={selectTabIndex}
                onTabClick={(index) => {
                  setSelectTabIndex(index);
                }}
                data={{ ...dataList[selectIndex] }}
                isLoggedinUserProfile={isLoggedinUserProfile}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <Sidebar /> */}
    </div>
  );
}

Profile.propTypes = {
  profile: PropTypes.object,
  serverError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  processing: PropTypes.bool,
  handleGetProfile: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.profile,
    serverError: state.profileReducer.serverError,
    processing: state.profileReducer.processing
  };
};

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(Profile)));
