// @ts-check
import React, { useEffect, useMemo, useState } from "react";
import { AppBar, Button, Grid } from "@material-ui/core";


const get1stLevelCategories = (allCategories, mode) => {
  if (!allCategories || allCategories.length === 0) {
    return [];
  }
  return allCategories.filter(
    (e) => e.parentTitle?.toUpperCase() === mode.toUpperCase()
  );
}

const getSelectedCategories = (allCategories, mode, selectedCategoryIds) => {
  const newCategories = get1stLevelCategories(allCategories, mode);
  if (selectedCategoryIds.length === 0) {
    return newCategories;
  }

  if (selectedCategoryIds.length === 1) {
    return allCategories.filter((e) => e.parentId === selectedCategoryIds[0]);
  }

  return allCategories.filter((c) => c.id === selectedCategoryIds[1]);
};

/**
 * @typedef {Object} IndexProps
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} allCategories
 * @property {string} mode
 * @property {(cat: import("shared/react-query/strapiAPI").HCNCategory[]) => void} onCategoriesChange
 * @property {Record<string, any>} classes
 */

/**
 * @param {IndexProps} props
 */
const Index = (props) => {
  const { classes, allCategories, mode, onCategoriesChange } = props;

  /** @type {[number[], (a: number[]) => void]} */
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

  //1level categories
  const categories = useMemo(() => get1stLevelCategories(allCategories, mode), [allCategories, mode]);

  // 2nd level categories
  const subCategories = useMemo(() =>
    selectedCategoryIds.length > 0 ?
      getSelectedCategories(allCategories, mode, [selectedCategoryIds[0]]) :
      null,
    [allCategories, mode, selectedCategoryIds]);

  // console.log(allCategories, mode, selectedCategoryIds, categories, subCategories);

  let articlePageCheck = window.location.href.includes("articles");
  const articleBackground = `rgba(255, 255, 255, 0.1)`;


  // whenever the mode changes(person/planet), the sub-categories displayed should also change
  useEffect(() => {
    console.log("on mode and allCategories change");
    if (categories.length === 0) {
      return;
    }

    const selectedCategories = getSelectedCategories(allCategories, mode, selectedCategoryIds);
    onCategoriesChange(selectedCategories);

  }, [allCategories, mode]);

  const handleTabChange = (tab) => {
    let newSelectedCategoryIds = [tab.id];
    if (selectedCategoryIds.length === 1 && selectedCategoryIds[0] === tab.id) {
      newSelectedCategoryIds = [];
    }

    const selectedCategories = getSelectedCategories(allCategories, mode, newSelectedCategoryIds)

    setSelectedCategoryIds(newSelectedCategoryIds);
    onCategoriesChange(selectedCategories);
  };

  const onSubCategoryChange = (categoryId) => {
    let newSelectedCategoryIds = [selectedCategoryIds[0], categoryId];
    if (selectedCategoryIds.length === 1 && selectedCategoryIds[1] === categoryId) {
      newSelectedCategoryIds = [selectedCategoryIds[0]];
    }

    const selectedCategories = getSelectedCategories(allCategories, mode, newSelectedCategoryIds);

    setSelectedCategoryIds(newSelectedCategoryIds);
    onCategoriesChange(selectedCategories);
  };

  if (!allCategories || allCategories.length === 0 || !mode) {
    return <Grid>Loading...</Grid>;
  }

  return (
    <div>
      <AppBar
        position="static"
        style={{
          background: articlePageCheck ? articleBackground : "default",
          border: articlePageCheck ? "1px solid white" : "none",
          color: articlePageCheck ? "white" : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "15px",
          }}
        >
          {categories?.map((tab, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                cursor: "pointer",
                color: selectedCategoryIds.length > 0 ? "rgba(0,0,0,0.5)" : "inherit",
              }}
              onClick={() => handleTabChange(tab)}
            >
              {/* <img src={tab.icon} alt="Tab Icon" style={{height: 15}} /> */}
              <div
                style={{
                  // borderRadius: "10px",
                  // boxShadow: `0px 0px 0px 3px ${tab.attributes.color}, 0px 0px 0px 2px ${tab.attributes.color}`,
                  // width: "20px",
                  // height: "20px",
                  background: `${tab.color}`,
                  border: "2px solid white",
                  borderRadius: "30px",
                  height: "12px",
                  width: "12px",
                  position: "relative",
                  boxShadow: `0 0 0 1px ${tab.color}`,
                }}
              ></div>

              <span
                id="category"
                style={{
                  fontFamily: "Roboto",
                  textDecoration: selectedCategoryIds.length > 1 && selectedCategoryIds[0] === tab.id ? "underline" : "",
                  color: articlePageCheck ? "white" : "black",
                }}
              >
                {tab.title.split("-")[0]}
              </span>
            </div>
          ))}
        </div>
      </AppBar>
      {selectedCategoryIds.length > 0 && (
        <div>
          <div
            className={classes.subCategoryContainer}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              overflow: "auto",
              marginTop: 0,
              // background: "rgb(205 205 205)",
            }}
          >
            {subCategories?.map((cat, index) => (
              <Button
                key={index}
                color="default"
                className={classes.subCategoryButton}
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "min-content",
                  color: "white !important",
                  borderRadius: 0,
                  borderBottom: selectedCategoryIds.length == 2 && cat.id === selectedCategoryIds[1] ? "2px solid white" : "none",
                }}
                onClick={() => onSubCategoryChange(cat.id)}
              >
                <span
                  style={{
                    width: "2px",
                    height: "8.5px",
                    marginRight: "4px",
                    borderRight: `2px solid ${cat?.color}`,
                  }}
                />{" "}
                <span style={{ color: "white" }}>{cat?.title}</span>
              </Button>
            ))}
          </div>

        </div>
      )}
    </div>
  );
};

export default Index;
