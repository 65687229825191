import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { ExpandBar } from "~/components/ui";
import styles from "./ArticlesMainStyles";
import MyFeed from "~/components/layout/MyFeed/index";
import ToggleContentsTitles from "~/components/ui/ToggleContentTitles";
import DisplayContentItems from "../../contentData/DisplayContentItems";
import { fetchArticlesByCategoryIds, fetchHCNArticleCategoriesWarper } from "shared/react-query/strapiAPI";
import ArticleCardBox from "./ArticleCardBox";
import { useQuery } from "react-query";

const customStylesMyFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'transparent',
  },
  subCategoryButton: {
  },
});

const MyFeedCustomStyles = withStyles(customStylesMyFeed)(MyFeed);

const useStyles = makeStyles(styles);

const Articles = (props) => {
  const classes = useStyles(props);
  const {
    mode,
    handleSwitch,
    titles,
    selectedTitleIds,
    onTitlesChange,
    goDetail,
    goExpanded,
  } = props;

  const allCategories = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategoriesWarper(),
    { staleTime: Infinity }
  );

  const [categories, setCategories] = useState(null);

  const onCategoriesChangeHandler = (selectedCategories) => {
    setCategories(selectedCategories);
  };

  return (
    <Container className={classes.root}>
      <ExpandBar
        nav="Articles"
        subNav={mode}
        goExpanded={goExpanded}
        switcher={{
          items: ["Person", "Planet"],
          checkedIndex: mode === "planet" ? 1 : 0,
          onChange: handleSwitch,
        }}
      />
      <ToggleContentsTitles
        titles={titles}
        selectedIds={selectedTitleIds}
        onChange={onTitlesChange}
      />
      <MyFeedCustomStyles allCategories={allCategories.data ?? []} mode={mode} onCategoriesChange={onCategoriesChangeHandler} />
      <DisplayContentItems
        allCategories={allCategories.data ?? []}
        filteredCategories={categories}
        goDetail={goDetail}
        // how to fetch data
        dataName="articles"
        fetcher={fetchArticlesByCategoryIds}
        // how to display data
        itemComponent={ArticleCardBox}
      />
    </Container>
  );
};

export default Articles;
