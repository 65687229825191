import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ArticlesMain from "./Sections/ArticlesMain";
import ArticlesMainExpanded from "./Sections/ArticlesMainExpanded";
import styles from "./ArticlesMainPageStyles";
import { tableTitles } from "~/data/articlesData";

const PERSON = "person";
const PLANET = "planet";

class ArticlesMainPage extends Component {
  state = {
    expanded: false,
    selectedTitleIds: [],
    mode: PERSON,
    articles: [],
    loading: true,
    error: null,
  };

  goExpanded = (e) => {
    this.setState((preState) => ({ expanded: !preState.expanded }));
    this.props.history.push(`?expanded=true&mode=${this.state.mode}`);
  };

  goDetail = (e) => {
    this.props.history.push({
      pathname: `/content/articles/${e.slug}`,
    });
  };

  setExpended = () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const expanded = Boolean(
      params.get("expanded") && params.get("expanded").toUpperCase() === "TRUE"
    );
    const mode = Boolean(params.get(PLANET)) ? PLANET : PERSON;
    this.setState((prev) => {
      if (mode !== prev.mode || expanded !== prev.expanded) {
        return {
          mode,
          expanded,
        };
      } else {
        return null;
      }
    });
  };

  async componentDidMount() {
    this.setExpended();
    // try {
    //   const data = await getArticles();
    //   console.log(data);
    //   this.setState({ articles: data.data, loading: false });
    // } catch (error) {
    //   this.setState({ error, loading: false });
    // }
  }

  componentDidUpdate() {
    this.setExpended();
  }

  handleTitlesChange = (selectedTitleIds) => {
    this.setState({ selectedTitleIds });
  };

  render() {
    const { classes } = this.props;
    const { articles, selectedTitleIds, mode, expanded } = this.state;
    const data =
      selectedTitleIds.length > 0
        ? articles.filter(
            (item) => selectedTitleIds.indexOf(item.categoryId) > -1
          )
        : articles;
    const Content = expanded ? ArticlesMainExpanded : ArticlesMain;
    return (
      <div className={classes.backgroundWrapper}>
        <div className={classes.content}>
          <Content
            data={data}
            goDetail={this.goDetail}
            goExpanded={this.goExpanded}
            titles={tableTitles}
            selectedTitleIds={selectedTitleIds}
            onTitlesChange={this.handleTitlesChange}
            mode={mode}
            handleSwitch={() => {
              const nextMode = mode === PERSON ? PLANET : PERSON;
              this.setState({ mode: nextMode });
              this.props.history.replace(`?${nextMode}=true`);
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ArticlesMainPage)
);
