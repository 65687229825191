import React from 'react';
import {withStyles, Grid, Divider} from '@material-ui/core';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';

const styles = (theme) => ({
  root: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    paddingBottom: '2px',
    borderTopRightRadius: '20px'
  },
  tabItem: {
    flex: 1,
    textAlign: 'center',
    minHeight: '45px',
    cursor: 'pointer',
    padding: '5px 0',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectTab: {
    backgroundColor: 'rgba(204, 204, 204, 0.5)'
  },
  firstItem: {
    borderTopLeftRadius: '15px'
  },
  lastItem: {
    borderTopRightRadius: '15px'
  },
  divider: {
    height: '30px',
    alignSelf: 'center'
  }
});

function ContentTab(props) {
  const {classes, tabs, selectIndex, onTabClick, isLoggedinUserProfile} = props;
  return (
    <Grid container className={classes.root}>
      {tabs.map((tab, index) => {
        if(tab.visibleToProfileOwnerOnly){
          console.log(isLoggedinUserProfile,'isLoggedinUserProfile')
          if(isLoggedinUserProfile){
            return (
              <>
                <Grid
                  item
                  container
                  key={index}
                  className={clsx(classes.tabItem, {
                    [classes.selectTab]: index === selectIndex,
                    [classes.firstItem]: index === 0,
                    [classes.lastItem]: index === tabs.length - 1
                  })}
                  onClick={() => {onTabClick(index)}}
                >
                  {tab.icon && isMobile?<img style={{width:30}} src={tab.icon}/>:tab.name}
                </Grid>
                {index !== tabs.length - 1 ? <Divider orientation="vertical" flexItem className={classes.divider} /> : null}
              </>
            );
          }
          return <></>
        }
        return (
          <>
            <Grid
              item
              container
              key={index}
              className={clsx(classes.tabItem, {
                [classes.selectTab]: index === selectIndex,
                [classes.firstItem]: index === 0,
                [classes.lastItem]: index === tabs.length - 1
              })}
              onClick={() => {onTabClick(index)}}
            >
              {tab.icon && isMobile?<img style={{width:30}} src={tab.icon}/>:tab.name}
            </Grid>
            {index !== tabs.length - 1 ? <Divider orientation="vertical" flexItem className={classes.divider} /> : null}
          </>
        );
      })}
    </Grid>
  );
}

export default withStyles(styles)(ContentTab);
